import { Link } from 'gatsby'
import React from 'react'
import "../../../styles/AdminPage.scss";
import Navbar from '../../../sections/Navbar/Navbar';
import Footer from '../../../sections/Footer';

function AdminPage() {
    return (
        <>
            <Navbar />
            <div className="AdminHome">
                <span>
                    <b>L'application #Coachello</b> permet aux leaders émergents de trouver leurs opportunités de croissance et d'y travailler par le biais d'un coaching 1:1. Il s'agit d'un développement personnel 100 % personnalisé, intégré au lieu de travail.
                </span>
                <ul className="link-list">
                    <b>
                        <li><Link to="hr-admin-dashboard">• Dashboard pour les administrateurs RH</Link></li>
                        <li><Link to="install-coachello-app">• Cinq étapes pour installer l'application Coachello pour Slack</Link></li>
                    </b>
                </ul>
            </div>
            <Footer />
        </>
    )
}

export default AdminPage